import React from "react";
import Card from "../Card";

function comp(props) {
  // console.log(props);

  const cardBody = (
    <React.Fragment>
      <div className="form-row my-3 justify-content-center">
        <div className="col-12 mb-1">
          <input
            type="number"
            className="form-control"
            placeholder="litr"
            min="0"
            max="99999999"
          />
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify">
        برای کالیبره شدن و ست کردن ماژول با کنتور می‌توان عدد پایه را تعیین کرد.
        به این صورت چنانچه ماژول بعد از نصب کنتور راه اندازی شود و یا به هر
        دلیلی مغایرتی در اعداد رخ دهد بتوان اعداد رو با کنتور تنظیم کرد
      </small>
    </React.Fragment>
  );

  // const dir = "Port " + props.id;

  // let i;
  // let arr = [];
  // for (i = 1; i <= props.constants["Input Modules"].ports; i++) {
  //   arr.push({
  //     header: "Port " + i,
  //     path: dir + "/Port " + i,
  //   });
  // }
  // const cards = arr;

  const cards = [
    {
      header: "Starting Number",
      path: "#",
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing: "Min 0 litr; Max 99,999,999 litr",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
