import React from "react";
import Card from "../Card";

function HCDigitalInputMods(props) {
  // console.log(props.constants["Input Modules"]);

  const dir = "Input";
  let i;
  let arr = [];
  for (i = 1; i <= props.constants["Input Modules"].modules; i++) {
    arr.push({
      header: "Module " + i,
      path: dir + "/Module " + i,
    });
  }

  const cards = arr;

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default HCDigitalInputMods;
