import React from "react";
import Card from "../../Card";

function comp(props) {
  const dir = "Event";
  let i;
  let arr = [];
  for (i = 1; i <= props.constants["Actuator Modules"].timeEvents; i++) {
    arr.push({
      header: "Event " + i,
      path: dir + "/Event " + i,
    });
  }
  arr.push({
    header: "Add Event",
    path: dir + "/Add Event",
  });
  const cards = arr;

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
