import React from "react";
import Card from "../Card";

function isEmpty(str) {
  return !str || 0 === str.length;
}

function comp(props) {
  const dir = "IRM " + props.id;
  let situation = "";
  if (!isEmpty(props.swt)) {
    if (props.swt) {
      situation = 1;
    } else {
      situation = 0;
    }
  } else {
    situation = Math.round(Math.random());
  }

  const cards = [
    {
      header: "Situation",
      situation: situation,
    },
    {
      header: "Manual",
      // path: dir + "?swt=" + !situation,
      path: dir + "/Manual",
    },
    {
      header: "Starting Times & Duration",
      path: dir + "/Starting Times",
    },
    {
      header: "Schedule",
      path: dir + "/Schedule",
    },
    {
      header: "Cycle & Soak",
      path: dir + "/Cycle & Soak",
    },
    {
      header: "Sensor Sensitivity",
      path: dir + "/Sensor Sensitivity",
    },
    {
      header: "Sensorstart",
      path: dir + "/Sensorstart",
    },
    {
      header: "Assign Pump",
      path: dir + "/Assign Pump",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
