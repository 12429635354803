import React from "react";
import Card from "../Card";

function HCDigitalInputModN(props) {
  // console.log(props);

  const dir = "Port " + props.id;

  // let i;
  // let arr = [];
  // for (i = 1; i <= props.constants["Input Modules"].ports; i++) {
  //   arr.push({
  //     header: "Port " + i,
  //     path: dir + "/Port " + i,
  //   });
  // }
  // const cards = arr;

  const cards = [
    {
      header: "Define",
      path: dir + "/Define",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default HCDigitalInputModN;
