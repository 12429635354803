import React from "react";
import Card from "../Card";
import { Link } from "react-router-dom";

function Power() {
  const cardBody = (
    <React.Fragment>
      <Link to="/">
        <button className="btn btn-danger w-100">Restart</button>
      </Link>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Are you sure?",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default Power;
