import React from "react";
import Card from "../../Card";

function comp(props) {
  const dir = "Time Scene";

  const cards = [
    {
      header: "Event",
      path: dir + "/Event",
    },
    {
      header: "Assign",
      path: dir + "/Assign",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  return output;
}

export default comp;
