import React from "react";
import Card from "../../Card";

function comp(props) {
  let i;
  let arr = [];
  for (i = 1; i <= props.constants["Sensor Modules"].modules; i++) {
    arr.push(
      <React.Fragment>
        <label class="col-4 col-md-2 mb-2">
          Sensor {i} <small className="text-muted ml-2">named</small>
        </label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </React.Fragment>
    );
  }

  const cardBody = (
    <React.Fragment>
      <div className="form-group row">{arr}</div>
      <small className="form-text text-muted rtl text-justify mt-4">
        برای پورت actuator انتخاب شده میتوان به تعداد نیاز سنسور اضافه کرد تا در
        انتها با اپراتوری که از منوی قبل انتخاب کردید بین situation همه دکمه ها
        عملیات AND یا OR اعمال شده و نتیجه ضرب در Reverse شود و در انتها پاسخ هر
        چه بود، وضعیت پورت Actuator همان میشود
      </small>

      <button
        id="OK"
        onClick={() => window.history.go(-1)}
        className="btn btn-primary w-100 my-3"
      >
        OK
      </button>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Add Sensors",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
