import React from "react";
import Card from "../../Card";

function comp(props) {
  const dir = "Irrigation";
  let i;
  let arr = [];
  for (i = 1; i <= props.constants["Irrigation Modules"].modules; i++) {
    arr.push({
      header: "Module " + i,
      path: dir + "/Module " + i,
    });
  }

  const cards = arr;
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
