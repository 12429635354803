import React from "react";
import Card from "../Card";

function comp(props) {
  // console.log(props);

  const cardBody = (
    <React.Fragment>
      <div className="form-row my-3 justify-content-center">
        <div className="col-auto mt-1 text-right">
          <label>MNM - </label>
        </div>
        <div className="col-2 mb-1">
          <input type="number" max="9" min="0" className="form-control" />
        </div>
        <div className="col-2 mb-1">
          <input type="number" max="9" min="0" className="form-control" />
        </div>
        <div className="col-2 mb-1">
          <input type="number" max="9" min="0" className="form-control" />
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify">
        تغییر نام ماژول ها یا پورت ها. بعضی از ماژول ها تک پورت هستند مانند
        ماژول sensor و ماژول display و تنها نامگذاری خود ماژول قابلیت انجام دارد
      </small>
    </React.Fragment>
  );

  // const dir = "Port " + props.id;

  // let i;
  // let arr = [];
  // for (i = 1; i <= props.constants["Input Modules"].ports; i++) {
  //   arr.push({
  //     header: "Port " + i,
  //     path: dir + "/Port " + i,
  //   });
  // }
  // const cards = arr;

  const cards = [
    {
      header: "Define",
      path: "#",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
