import React from "react";
import Card from "../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="form-row">
        <div className="col-4 mt-1">
          <label>Tap</label>
        </div>
        <div className="col-8 mb-2">
          <input
            type="number"
            className="form-control form-control-sm"
            placeholder="300ms"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-4 mt-1">
          <label>Long Push 1</label>
        </div>
        <div className="col-8 mb-2">
          <input
            type="number"
            className="form-control form-control-sm"
            placeholder="3000ms"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-4 mt-1">
          <label>Long Push 2</label>
        </div>
        <div className="col-8 mb-2">
          <input
            type="number"
            className="form-control form-control-sm"
            placeholder="10000ms"
          />
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify mt-4">
        واحد بر حسب میلی ثانیه می‌باشد.
      </small>
      <small className="form-text text-muted rtl text-justify mt-2">
        {" "}
        مقداردهی حساسیت دکمه ها برای هر حالت. به عنوان مثال زمان مورد نیاز برای
        محسوب شدن به عنوان یک Tap میتواند ۳۰۰ میلی ثانیه باشد . به صورت پیش فرض
        اعداد ارائه شده می‌باشند.
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Button Touch Sensitivity",
      path: "#",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
