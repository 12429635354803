import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

// app components
import Constants from "./constants";
import Home from "./components/Home";
// Machine
import Machine from "./components/Machine";
import MaPower from "./components/Machine/Power";
import MaReset from "./components/Machine/Reset";
import MaErrors from "./components/Machine/Errors";
import MachineTimeDate from "./components/Machine/TimeDate";
import MaTime from "./components/Machine/Time";
import MaDate from "./components/Machine/Date";
import MaAutoLAN from "./components/Machine/AutoLAN";
import MaBI from "./components/Machine/BI";
import MaBTS from "./components/Machine/BTS";
// Module
import ModuleConfig from "./components/Module/index";
// Input modules
import InputModule from "./components/Module/Inputs";
import InputModuleN from "./components/Module/InputN";
import InputModulePortN from "./components/Module/InputPortN";
import ModDefine from "./components/Module/Define";
import ModPDIMDefine from "./components/Module/PDIMDefine";
import ModPACMDefine from "./components/Module/PACMDefine";
import ModPAOMDefine from "./components/Module/PAOMDefine";
import ModPIRMDefine from "./components/Module/PIRMDefine";
import ModPSEMDefine from "./components/Module/PSEMDefine";
import ModPMNMDefine from "./components/Module/PMNMDefine";
import ModPDPMDefine from "./components/Module/PDPMDefine";
import ModPPMMDefine from "./components/Module/PPMMDefine";
import SensorModule from "./components/Module/Sensors";
import SensorModuleN from "./components/Module/SensorN";
import SensorModulePortNDefine from "./components/Module/SensorNDefine";
// monitoring modules
import MonitoringModules from "./components/Module/monitoringModules";
import MonitoringModuleN from "./components/Module/monitoringModuleN";
import MonitoringModulePortN from "./components/Module/monitoringModulePortN";
import MonitoringModulePortNDefine from "./components/Module/monitoringModulePortNDefine";

// Actuator modules
import ActuatorModules from "./components/Module/actuatorModules";
import ActuatorModuleN from "./components/Module/actuatorModuleN";
import ActuatorModulePortN from "./components/Module/actuatorModulePortN";
import ActuatorModulePortNDefine from "./components/Module/actuatorModulePortNDefine";

// Actuator Solo modules
import ActuatorSoloModules from "./components/Module/actuatorSoloModules";
import ActuatorSoloModuleN from "./components/Module/actuatorSoloModuleN";
// import ActuatorModulePortN from "./components/Module/actuatorModulePortN";
// import ActuatorModulePortNDefine from "./components/Module/actuatorModulePortNDefine";

// Display modules
import DisplayModules from "./components/Module/Display/Modules";
import DisplayModuleN from "./components/Module/Display/ModuleN";
import DisplayModuleNDefine from "./components/Module/Display/ModuleNDefine";

// Pump modules
import PumpModules from "./components/Module/Pumps";
import PumpModuleN from "./components/Module/PumpN";
import PumpModuleNDefine from "./components/Module/PumpNDefine";
import PumpModuleNType from "./components/Module/PumpNType";

// FlowCtrl modules
import FlowCtrlModules from "./components/Module/FlowCtrls";
import FlowCtrlModuleN from "./components/Module/FlowCtrlN";
import FlowCtrlModuleNDefine from "./components/Module/FlowCtrlNDefine";
import FlowCtrlModuleNStartNum from "./components/Module/FlowCtrlNStartNum";
import FlowCtrlModuleNResetCounter from "./components/Module/FlowCtrlRstCount";

// WaterPress modules
import WaterPressModules from "./components/Module/WaterPresses";
import WaterPressModuleN from "./components/Module/WaterPressN";
import WaterPressModuleNDefine from "./components/Module/WaterPressNDefine";
import WaterPressModuleNRange from "./components/Module/WaterPressNRange";

// Temp modules
import TempModules from "./components/Module/Temps";
import TempModuleN from "./components/Module/TempN";
import TempModuleNDefine from "./components/Module/TempNDefine";

// Irrigation modules
import IrrigationModules from "./components/Module/Irrigation/Modules";
import IrrigationModuleN from "./components/Module/Irrigation/ModuleN";
import IrrigationModulePortN from "./components/Module/Irrigation/ModulePortN";
import IrrigationModulePortNDefine from "./components/Module/Irrigation/ModulePortNDefine";

import SoftwareConfig from "./components/Program";

// Program/ Buttons modules
// import SCButtons from "./components/Program/Butn/ModulePorts";
// import SCButtonModulePortN from "./components/Program/Butn/ModulePortN";
// import SCButtonPortNList from "./components/Program/Butn/ButtonList";
// import SCButtonPortNOperator from "./components/Program/Butn/Operator";
// import SCButtonPortNReverse from "./components/Program/Butn/Reverse";

// Program/ Time Scenes
import SCAutostart from "./components/Program/TimeScene";
import SCAutostartAsg from "./components/Program/TimeScene/ActuatorPorts";
import SCAutostartPortN from "./components/Program/TimeScene/ActuatorPortN";
import SCAutostartPortNEvList from "./components/Program/TimeScene/EventList";
import SCAutostartPortNOperator from "./components/Program/TimeScene/Operator";
import SCAutostartPortNReverse from "./components/Program/TimeScene/Reverse";
import SCAutostartEvs from "./components/Program/TimeScene/Events";
import SCAutostartEvAdd from "./components/Program/TimeScene/addEvent";
import SCAutostartEvN from "./components/Program/TimeScene/EventN";
import SCAutostartEvNRename from "./components/Program/TimeScene/EventDefine";
import SCAutostartEvNStartTime from "./components/Program/TimeScene/EventStartTime";
import SCAutostartEvNDuration from "./components/Program/TimeScene/EventDuration";
import SCAutostartEvNSensitivity from "./components/Program/TimeScene/EventSensitivity";

// Program/ Sensor
import SCSensors from "./components/Program/Sensor/ActuatorPorts";
import SCSensorModulePortN from "./components/Program/Sensor/ActuatorPortN";
import SCSensorPortNList from "./components/Program/Sensor/SensorList";
import SCSensorPortNOperator from "./components/Program/Sensor/Operator";
import SCSensorPortNReverse from "./components/Program/Sensor/Reverse";

// Program/ Monitoring
import SCMonitoring from "./components/Program/Monitoring/index";
import SCMonitoringButtons from "./components/Program/Monitoring/Buttons";
import SCMonitoringButtonPortN from "./components/Program/Monitoring/ButtonPortN";
import SCMonitoringButtonPortNModList from "./components/Program/Monitoring/PortList";
import SCMonitoringDCs from "./components/Program/Monitoring/DCs";
import SCMonitoringDCPortN from "./components/Program/Monitoring/DCPortN";
import SCMonitoringDCPortNModList from "./components/Program/Monitoring/PortList";
import SCMonitoringPortNOperator from "./components/Program/Monitoring/Operator";
import SCMonitoringPortNReverse from "./components/Program/Monitoring/Reverse";

// Butns
import Butns from "./components/Program/Butn/index";
import ButnN from "./components/Program/Butn/ButnN";
import ButnNType from "./components/Program/Butn/ButnNType";
import ButnNDuration from "./components/Program/Butn/Duration";
import ActList from "./components/Program/Butn/ActList";
import ButnSensitivity from "./components/Program/Butn/Sensitivity";

// Program/ Irrigation
import Irrigation from "./components/Irrigation";
import IrrGen from "./components/Irrigation/General";
import IrrGenStartTimes from "./components/Irrigation/GStartingTimes";
import IrrGenStartTimeN from "./components/Irrigation/GStartingTimeN";
import IrrGenDurations from "./components/Irrigation/GDurations";
import IrrGenDurationN from "./components/Irrigation/GDurationN";
import IrrGenCycles from "./components/Irrigation/GCycles";
import IrrGenCycleN from "./components/Irrigation/GCycleN";
import IrrGenSchedules from "./components/Irrigation/GSchedules";
import IrrGenScheduleN from "./components/Irrigation/GScheduleN";
import IrrGenSeasonal from "./components/Irrigation/GSeasonal";
import IrrValves from "./components/Irrigation/Valves";
import IrrValveN from "./components/Irrigation/ValveN";
import IrrValveNManual from "./components/Irrigation/ValveNManual";
import IrrValveNStartTime from "./components/Irrigation/ValveNStartTime";
import IrrValveNStartTimeDur from "./components/Irrigation/ValveNDur";
import IrrValveNCycles from "./components/Irrigation/ValveNCycles";
import IrrValveNSchedule from "./components/Irrigation/ValveNSchedule";
import IrrValveNSensitivity from "./components/Irrigation/Sensitivity";
import IrrValveNSensorstart from "./components/Irrigation/Sensorstart";
import IrrGenPumps from "./components/Irrigation/Pumps";
import IrrValveNPmp from "./components/Irrigation/ValveNPmp";
import IrrPmps from "./components/Irrigation/Pumps";

// Report
import Report from "./components/Report/index";
import RTexts from "./components/Report/RTexts";
import RTextN from "./components/Report/RTextN";
import RDisplayMods from "./components/Report/DisplayModules";
import RDisplayModN from "./components/Report/DisplayModuleN";
import RInternal from "./components/Report/Internal";
import RSMS from "./components/Report/SMS";
import RWifi from "./components/Report/Wifi";
import Remote from "./components/Remote";

const constants = Constants;

class RoutingAddr extends Component {
  state = {};
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        {/* Machine */}
        <Route exact path="/Machine" component={Machine} />
        <Route exact path="/Machine/Power" component={MaPower} />
        <Route exact path="/Machine/Restart" component={MaReset} />
        <Route exact path="/Machine/Factory Reset" component={MaReset} />
        <Route exact path="/Machine/Errors" component={MaErrors} />
        <Route
          exact
          path="/Machine/Time & Date"
          render={(props) => <MachineTimeDate constants={constants} />}
        />
        <Route
          exact
          path="/Machine/Time & Date/Time"
          render={(props) => <MaTime constants={constants} />}
        />
        <Route
          exact
          path="/Machine/Time & Date/Date"
          render={(props) => <MaDate constants={constants} />}
        />
        <Route
          exact
          path="/Machine/Time & Date/Auto via LAN"
          render={(props) => <MaAutoLAN constants={constants} />}
        />

        {/* Blinking Intervals */}
        <Route
          exact
          path="/Machine/Blinking Intervals"
          render={(props) => <MaBI constants={constants} />}
        />

        {/* Module */}
        <Route exact path="/Module" component={ModuleConfig} />
        <Route exact path="/Module/Input">
          <InputModule constants={constants} />
        </Route>
        <Route
          exact
          path="/Module/Input/Module :id"
          render={(props) => (
            <InputModuleN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Input/Module :id/Define"
          render={(props) => (
            <ModDefine constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Input/Module :id/Port :portId"
          render={(props) => (
            <InputModulePortN
              constants={constants}
              id={props.match.params.portId}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Module/Input/Module :id/Port :portId/Define"
          render={(props) => (
            <ModPDIMDefine
              constants={constants}
              id={props.match.params.portId}
              portId={props.match.params.portId}
            />
          )}
        />

        <Route exact path="/Module/Sensor">
          <SensorModule constants={constants} />
        </Route>

        <Route
          exact
          path="/Module/Sensor/Module :id"
          render={(props) => (
            <SensorModuleN constants={constants} id={props.match.params.id} />
          )}
        />

        <Route
          exact
          path="/Module/Sensor/Module :id/Port :portId"
          render={(props) => (
            <SensorModuleN
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Module/Sensor/Module :id/Define"
          render={(props) => (
            <SensorModulePortNDefine
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />
        {/* Monitoring */}
        <Route
          exact
          path="/Module/Monitoring"
          render={(props) => (
            <MonitoringModules
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Module/Monitoring/Module :id"
          render={(props) => (
            <MonitoringModuleN
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Module/Monitoring/Module :id/Define"
          render={(props) => (
            <SensorModulePortNDefine
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Module/Monitoring/Module :id/Port :portId"
          render={(props) => (
            <MonitoringModulePortN
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Module/Monitoring/Module :id/Port :portId/Define"
          render={(props) => (
            <ModPMNMDefine
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />

        {/* Actuator */}
        <Route
          exact
          path="/Module/Actuator"
          render={(props) => <ActuatorModules constants={constants} />}
        />
        <Route
          exact
          path="/Module/Actuator/Module :id"
          render={(props) => (
            <ActuatorModuleN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Actuator/Module :id/Define"
          render={(props) => (
            <ModDefine constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Actuator/Module :id/Port :portId"
          render={(props) => (
            <ActuatorModulePortN
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Module/Actuator/Module :id/Port :portId/Define"
          render={(props) => (
            <ModPACMDefine
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />

        {/* Actuator Solo */}
        <Route
          exact
          path="/Module/Actuator Solo"
          render={(props) => <ActuatorSoloModules constants={constants} />}
        />
        <Route
          exact
          path="/Module/Actuator Solo/Module :id"
          render={(props) => (
            <ActuatorSoloModuleN
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Module/Actuator Solo/Module :id/Define"
          render={(props) => (
            <ModPAOMDefine
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />

        {/* Display */}
        <Route
          exact
          path="/Module/Display"
          render={(props) => <DisplayModules constants={constants} />}
        />
        <Route
          exact
          path="/Module/Display/Module :id"
          render={(props) => (
            <DisplayModuleN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Display/Module :id/Define"
          render={(props) => (
            <DisplayModuleNDefine
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />

        {/* Pump */}
        <Route
          exact
          path="/Module/Pump"
          render={(props) => <PumpModules constants={constants} />}
        />
        <Route
          exact
          path="/Module/Pump/Module :id"
          render={(props) => (
            <PumpModuleN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Pump/Module :id/Define"
          render={(props) => (
            <PumpModuleNDefine
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Module/Pump/Module :id/Type"
          render={(props) => (
            <PumpModuleNType constants={constants} id={props.match.params.id} />
          )}
        />

        {/* Flow Control */}
        <Route
          exact
          path="/Module/Flow Control"
          render={(props) => <FlowCtrlModules constants={constants} />}
        />
        <Route
          exact
          path="/Module/Flow Control/Module :id"
          render={(props) => (
            <FlowCtrlModuleN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Flow Control/Module :id/Define"
          render={(props) => (
            <FlowCtrlModuleNDefine
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Module/Flow Control/Module :id/Starting Number"
          render={(props) => (
            <FlowCtrlModuleNStartNum
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Module/Flow Control/Module :id/Reset Counter"
          render={(props) => (
            <FlowCtrlModuleNResetCounter
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />

        {/* Temperature */}
        <Route
          exact
          path="/Module/Temperature"
          render={(props) => <TempModules constants={constants} />}
        />
        <Route
          exact
          path="/Module/Temperature/Module :id"
          render={(props) => (
            <TempModuleN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Temperature/Module :id/Define"
          render={(props) => (
            <TempModuleNDefine
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />

        {/* Water Pressure */}
        <Route
          exact
          path="/Module/Water Pressure"
          render={(props) => <WaterPressModules constants={constants} />}
        />
        <Route
          exact
          path="/Module/Water Pressure/Module :id"
          render={(props) => (
            <WaterPressModuleN
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Module/Water Pressure/Module :id/Define"
          render={(props) => (
            <WaterPressModuleNDefine
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Module/Water Pressure/Module :id/Range"
          render={(props) => (
            <WaterPressModuleNRange
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />

        {/* Irrigation */}
        <Route
          exact
          path="/Module/Irrigation"
          render={(props) => <IrrigationModules constants={constants} />}
        />
        <Route
          exact
          path="/Module/Irrigation/Module :id"
          render={(props) => (
            <IrrigationModuleN
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Module/Irrigation/Module :id/Define"
          render={(props) => (
            <ModDefine constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Module/Irrigation/Module :id/Port :portId"
          render={(props) => (
            <ModPIRMDefine
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Module/Irrigation/Module :id/Port :portId/Define"
          render={(props) => (
            <IrrigationModulePortNDefine
              constants={constants}
              id={props.match.params.id}
              portId={props.match.params.portId}
            />
          )}
        />

        <Route exact path="/Program" component={SoftwareConfig} />

        {/* Button Calibration */}
        <Route
          exact
          path="/Program/Button Calibration"
          render={(props) => <MaBTS constants={constants} />}
        />

        {/* Button Assignment */}
        <Route
          exact
          path="/Program/Button Assignment"
          render={(props) => <Butns constants={constants} />}
        />
        <Route
          exact
          path="/Program/Button Assignment/DIM :id"
          render={(props) => (
            <ButnN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Program/Button Assignment/DIM :id/:name"
          render={(props) => (
            <ButnNType
              constants={constants}
              id={props.match.params.id}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Button Assignment/DIM :id/:name/Actuator List"
          render={(props) => (
            <ActList
              constants={constants}
              id={props.match.params.id}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Button Assignment/DIM :id/:name/Duration"
          render={(props) => (
            <ButnNDuration
              constants={constants}
              id={props.match.params.id}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Button Assignment/DIM :id/:name/Sensor Sensitivity"
          render={(props) => (
            <ButnSensitivity
              constants={constants}
              id={props.match.params.id}
              name={props.match.params.name}
            />
          )}
        />

        {/* SC/Buttons
        <Route
          exact
          path="/Program/Buttons"
          render={(props) => <SCButtons constants={constants} />}
        />
        <Route
          exact
          path="/Program/Buttons/Actuator Port :portId"
          render={(props) => (
            <SCButtonModulePortN
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Buttons/Actuator Port :portId/Button List"
          render={(props) => (
            <SCButtonPortNList
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Buttons/Actuator Port :portId/Operator"
          render={(props) => (
            <SCButtonPortNOperator
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Buttons/Actuator Port :portId/Reverse"
          render={(props) => (
            <SCButtonPortNReverse
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        /> */}

        {/* SC/Time Scene */}
        <Route
          exact
          path="/Program/Time Scene"
          render={(props) => <SCAutostart constants={constants} />}
        />
        <Route
          exact
          path="/Program/Time Scene/Assign"
          render={(props) => <SCAutostartAsg constants={constants} />}
        />
        <Route
          exact
          path="/Program/Time Scene/Assign/:name :portId"
          render={(props) => (
            <SCAutostartPortN
              constants={constants}
              portId={props.match.params.portId}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Assign/:name :portId/Event List"
          render={(props) => (
            <SCAutostartPortNEvList
              constants={constants}
              portId={props.match.params.portId}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Assign/:name :portId/Operator"
          render={(props) => (
            <SCAutostartPortNOperator
              constants={constants}
              portId={props.match.params.portId}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Assign/:name :portId/Reverse"
          render={(props) => (
            <SCAutostartPortNReverse
              constants={constants}
              portId={props.match.params.portId}
              name={props.match.params.name}
            />
          )}
        />

        {/* Event */}
        <Route
          exact
          path="/Program/Time Scene/Event/"
          render={(props) => (
            <SCAutostartEvs
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Event/Add Event"
          render={(props) => (
            <SCAutostartEvAdd
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Event/Event :id"
          render={(props) => (
            <SCAutostartEvN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Event/Event :id/Rename"
          render={(props) => (
            <SCAutostartEvNRename
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Event/Event :id/Start Time"
          render={(props) => (
            <SCAutostartEvNStartTime
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Event/Event :id/Duration"
          render={(props) => (
            <SCAutostartEvNDuration
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Program/Time Scene/Event/Event :id/Sensor Sensitivity"
          render={(props) => (
            <SCAutostartEvNSensitivity
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />

        {/* SC/Sensor */}
        <Route
          exact
          path="/Program/Sensor Scene"
          render={(props) => <SCSensors constants={constants} />}
        />
        <Route
          exact
          path="/Program/Sensor Scene/:name :portId"
          render={(props) => (
            <SCSensorModulePortN
              constants={constants}
              portId={props.match.params.portId}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Sensor Scene/:name :portId/Sensor List"
          render={(props) => (
            <SCSensorPortNList
              constants={constants}
              portId={props.match.params.portId}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Sensor Scene/:name :portId/Operator"
          render={(props) => (
            <SCSensorPortNOperator
              constants={constants}
              portId={props.match.params.portId}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Program/Sensor Scene/:name :portId/Reverse"
          render={(props) => (
            <SCSensorPortNReverse
              constants={constants}
              portId={props.match.params.portId}
              name={props.match.params.name}
            />
          )}
        />

        {/* SC/Monitoring */}
        <Route
          exact
          path="/Program/Monitoring"
          render={(props) => <SCMonitoring constants={constants} />}
        />
        <Route
          exact
          path="/Program/Monitoring/Input Module"
          render={(props) => <SCMonitoringButtons constants={constants} />}
        />
        <Route
          exact
          path="/Program/Monitoring/Input Module/DIM :portId"
          render={(props) => (
            <SCMonitoringButtonPortN
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Monitoring/Input Module/DIM :portId/Module Port List"
          render={(props) => (
            <SCMonitoringButtonPortNModList
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Monitoring/Input Module/DIM :portId/Operator"
          render={(props) => (
            <SCMonitoringPortNOperator
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Monitoring/Input Module/DIM :portId/Reverse"
          render={(props) => (
            <SCMonitoringPortNReverse
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Monitoring/Monitoring Module"
          render={(props) => <SCMonitoringDCs constants={constants} />}
        />
        <Route
          exact
          path="/Program/Monitoring/Monitoring Module/MNM :portId/"
          render={(props) => (
            <SCMonitoringDCPortN
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Monitoring/Monitoring Module/MNM :portId/Module Port List"
          render={(props) => (
            <SCMonitoringDCPortNModList
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Monitoring/Monitoring Module/MNM :portId/Operator"
          render={(props) => (
            <SCMonitoringPortNOperator
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />
        <Route
          exact
          path="/Program/Monitoring/Monitoring Module/MNM :portId/Reverse"
          render={(props) => (
            <SCMonitoringPortNReverse
              constants={constants}
              portId={props.match.params.portId}
            />
          )}
        />

        {/* Irrigation */}

        <Route exact path="/Irrigation" component={Irrigation} />
        <Route
          exact
          path="/Irrigation/General"
          render={(props) => <IrrGen constants={constants} />}
        />
        <Route
          exact
          path="/Irrigation/General/Starting Times"
          render={(props) => <IrrGenStartTimes constants={constants} />}
        />
        <Route
          exact
          path="/Irrigation/General/Starting Times/:name"
          render={(props) => (
            <IrrGenStartTimeN
              constants={constants}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/General/Durations"
          render={(props) => <IrrGenDurations constants={constants} />}
        />
        <Route
          exact
          path="/Irrigation/General/Durations/:name"
          render={(props) => (
            <IrrGenDurationN
              constants={constants}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/General/Cycle & Soak"
          render={(props) => <IrrGenCycles constants={constants} />}
        />
        <Route
          exact
          path="/Irrigation/General/Cycle & Soak/Cycle & Soak :id"
          render={(props) => (
            <IrrGenCycleN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Irrigation/General/Schedules"
          render={(props) => <IrrGenSchedules constants={constants} />}
        />
        <Route
          exact
          path="/Irrigation/General/Schedules/:name/"
          render={(props) => (
            <IrrGenScheduleN
              constants={constants}
              name={props.match.params.name}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/General/Seasonal Coefficient"
          render={(props) => <IrrGenSeasonal constants={constants} />}
        />
        <Route
          exact
          path="/Irrigation/Valves"
          render={(props) => <IrrValves constants={constants} />}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id"
          render={(props) => (
            <IrrValveN
              constants={constants}
              id={props.match.params.id}
              swt={props.match.params.swt}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id/Manual"
          render={(props) => (
            <IrrValveNManual
              constants={constants}
              id={props.match.params.id}
              location={props.location}
              match={props.match}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id/Starting Times"
          render={(props) => (
            <IrrValveNStartTime
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id/Starting Times/Duration"
          render={(props) => (
            <IrrValveNStartTimeDur
              constants={constants}
              id={props.match.params.id}
              location={props.location}
              match={props.match}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id/Cycle & Soak"
          render={(props) => (
            <IrrValveNCycles constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id/Schedule"
          render={(props) => (
            <IrrValveNSchedule
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id/Sensor Sensitivity"
          render={(props) => (
            <IrrValveNSensitivity
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id/Sensorstart"
          render={(props) => (
            <IrrValveNSensorstart
              constants={constants}
              id={props.match.params.id}
            />
          )}
        />
        <Route
          exact
          path="/Irrigation/Pumps/"
          render={(props) => (
            <IrrGenPumps constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Irrigation/Valves/IRM :id/Assign Pump"
          render={(props) => (
            <IrrValveNPmp constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Irrigation/Pumps"
          render={(props) => (
            <IrrPmps constants={constants} id={props.match.params.id} />
          )}
        />
        {/* Report */}
        <Route
          exact
          path="/Report"
          render={(props) => <Report constants={constants} />}
        />
        <Route
          exact
          path="/Report/Report Texts"
          render={(props) => <RTexts constants={constants} />}
        />
        <Route
          exact
          path="/Report/Report Texts/Report Text :id"
          render={(props) => (
            <RTextN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Report/Display Modules"
          render={(props) => <RDisplayMods constants={constants} />}
        />
        <Route
          exact
          path="/Report/Display Modules/Display Module :id"
          render={(props) => (
            <RDisplayModN constants={constants} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Report/Internal"
          render={(props) => <RInternal constants={constants} />}
        />
        <Route
          exact
          path="/Report/SMS"
          render={(props) => <RSMS constants={constants} />}
        />
        <Route
          exact
          path="/Report/:name"
          render={(props) => (
            <RWifi constants={constants} name={props.match.params.name} />
          )}
        />

        <Route exact path="/Remote" component={Remote} />
        <Route
          exact
          path="/Remote/:name"
          render={(props) => (
            <RWifi constants={constants} name={props.match.params.name} />
          )}
        />
      </Switch>
    );
  }
}

export default RoutingAddr;
