import React from "react";
import Card from "./Card";

function Home() {
  const cards = [
    // { header: "Power", path: "/Power" },
    { header: "Machine", path: "/Machine" },
    { header: "Module", path: "Module" },
    { header: "Program", path: "Program" },
    { header: "Irrigation", path: "Irrigation" },
    { header: "Report", path: "Report" },
    { header: "Remote", path: "Remote" },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default Home;
