import React from "react";
import Card from "../Card";

function comp(props) {
  let i = 0;
  let result = [];
  const options = (arg) => {
    for (i = 30; i <= 200; i = i + 10) {
      result.push(<option value={i}>{i + arg}</option>);
    }
    return result;
  };
  const cardBody = (
    <React.Fragment>
      <select className="custom-select mr-sm-2" defaultValue="100">
        {options("%")}
      </select>
      <small className="form-text text-muted rtl text-justify mt-4">
        برای گیاهان به طور کلی یکبار برنامه آبیاری داده میشود ولی در تابستان
        گیاهان به آب بیشتری احتیاج دارند لذا برای حل کردن این موضوع در فصول گرم
        برای گیاهان برنامه آبیاری داده می‌شود و برای فصول سردتر سال ضریبی به
        عنوان ضریب فصلی وجود دارد که در فصول سردتر سال با انتخاب درصدی، به آن
        اندازه در زمان آبیاری به صورت خودکار کاهش اعمال می‌گردد.
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Seasonal Coefficient",
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing:
        "Hunter uses 30% as a min for Seasonal Adjustment ratio so do we and Max 200%",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
