import React from "react";
import Card from "../Card";

function comp(props) {
  let i;
  let arr = [];
  for (i = 1; i <= props.constants["Pump Modules"].modules; i++) {
    arr.push(
      <React.Fragment>
        <option value={i}>
          Pump {i} {"<"}named{">"}
        </option>
      </React.Fragment>
    );
  }
  // arr = (
  //   <React.Fragment>
  //     <div className="col text-center my-3">
  //       <small className="text-muted">empty!</small>
  //     </div>
  //   </React.Fragment>
  // );

  const cardBody = (
    <React.Fragment>
      <div className="form-group row">
        <label class="col-4 col-md-2 mt-2">Pumps</label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.floor(Math.random() * 4)}
          >
            <option value="0">None</option>
            {arr}
          </select>
        </div>
      </div>

      <small className="form-text text-muted rtl text-justify">
        به هر شیری در مدار می‌توان یک پمپ نسبت داد، به صورتی که هر زمانی که شیری
        به پمپی نسبت داده شده باشد، با روشن شدن خط شیر برقی، پمپ مربوط به شیر
        برقی نیز روشن می‌گردد. به عنوان مثال: اگر 10 خط شیر برقی به پمپ شماره 1
        نسبت داده شده باشند، هر کدام از 10 خط شیر برقی که شروع به کار
        کنند،‌همزمان ماژول پمپ هم اقدام به روشن نمودن پمپ خواهد کرد
      </small>

      <button
        id="OK"
        onClick={() => window.history.go(-1)}
        className="btn btn-primary w-100 my-3"
      >
        OK
      </button>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Assign Pump",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
