import React, { Component } from "react";
import Card from "../Card";
import queryString from "query-string";

function ValveNDur(props) {
  const parsed = queryString.parse(props.location.search);
  let n1 = "",
    n2 = "",
    n3 = "",
    n4 = "",
    n5 = "",
    n6 = "";
  const selecteeNum = parsed.item
    ? parseInt(parsed.item)
    : Math.floor(Math.random() * 6) + 1;

  switch (selecteeNum) {
    case 1:
      n1 = "\u2713";
      break;
    case 2:
      n2 = "\u2713";
      break;
    case 3:
      n3 = "\u2713";
      break;
    case 4:
      n4 = "\u2713";
      break;
    case 5:
      n5 = "\u2713";
      break;
    case 6:
      n6 = "\u2713";
      break;

    default:
      break;
  }

  console.log(n5);
  const cards = [
    {
      header: "OFF " + n1,
      path: "?item=1",
    },
    {
      header: "Very Low " + n2,
      path: "?item=2",
    },
    {
      header: "Low " + n3,
      path: "?item=3",
    },
    {
      header: "Medium " + n4,
      path: "?item=4",
    },
    {
      header: "High " + n5,
      path: "?item=5",
    },
    {
      header: "Very High " + n6,
      path: "?item=6",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default ValveNDur;
