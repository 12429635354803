import React from "react";
import Card from "../../Card";

function comp(props) {
  let i;
  let arr = [];
  let digit = "";

  // Actuator Port
  for (
    i = 1;
    i <=
    props.constants["Actuator Modules"].modules *
      props.constants["Actuator Modules"].ports;
    i++
  ) {
    if (i < 10) {
      digit = "00" + i;
    }
    if ((i > 10) & (i < 100)) {
      digit = "0" + i;
    }
    arr.push(
      <React.Fragment>
        <label class="col-4 col-md-2 mb-2">ACM {digit}</label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
  // Actuator Solo Port
  for (i = 1; i <= props.constants["Actuator Solo Modules"].modules; i++) {
    if (i < 10) {
      digit = "00" + i;
    }
    if ((i > 10) & (i < 100)) {
      digit = "0" + i;
    }
    arr.push(
      <React.Fragment>
        <label class="col-4 col-md-2 mb-2">AOM {digit}</label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
  // Valve Port
  for (
    i = 1;
    i <=
    props.constants["Irrigation Modules"].modules *
      props.constants["Irrigation Modules"].ports;
    i++
  ) {
    if (i < 10) {
      digit = "00" + i;
    }
    if ((i > 10) & (i < 100)) {
      digit = "0" + i;
    }
    arr.push(
      <React.Fragment>
        <hr />
        <label class="col-4 col-md-2 mb-2">IRM {digit}</label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
  // Sensor
  for (i = 1; i <= props.constants["Sensor Modules"].modules; i++) {
    arr.push(
      <React.Fragment>
        <hr />
        <label class="col-4 col-md-2 mb-2">
          Sensor {i} <small className="text-muted ml-2">named</small>
        </label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
  // Pump
  for (i = 1; i <= props.constants["Pump Modules"].modules; i++) {
    arr.push(
      <React.Fragment>
        <hr />
        <label class="col-4 col-md-2 mb-2">
          Pump {i} <small className="text-muted ml-2">named</small>
        </label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </React.Fragment>
    );
  }

  const cardBody = (
    <React.Fragment>
      <div className="form-group row">{arr}</div>
      <small className="form-text text-muted rtl text-justify mt-4">
        برای پورت مانیتورینگ انتخاب شده میتوان به تعداد نیاز سنسور اضافه کرد تا
        در، پورت مانیتورینگ و شیر برقی انتها با اپراتوری که از منوی قبل انتخاب
        کردید بین situation همه دکمه ها عملیات AND یا OR اعمال شده و نتیجه ضرب
        در Reverse شود و در انتها پاسخ هر چه بود، وضعیت پورت مانیتورینگ همان
        میشود
      </small>

      <button
        id="OK"
        onClick={() => window.history.go(-1)}
        className="btn btn-primary w-100 my-3"
      >
        OK
      </button>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Add Module",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
