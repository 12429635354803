import React from "react";
import Card from "../Card";

function ModuleConfig() {
  const dir = "Module";
  const cards = [
    { header: "Input", path: dir + "/Input", abbr: "DIM" },
    { header: "Actuator", path: dir + "/Actuator", abbr: "ACM" },
    { header: "Actuator Solo", path: dir + "/Actuator Solo", abbr: "AOM" },
    { header: "Irrigation", path: dir + "/Irrigation", abbr: "IRM" },
    { header: "Sensor", path: dir + "/Sensor", abbr: "SEM" },
    { header: "Monitoring", path: dir + "/Monitoring", abbr: "MNM" },
    { header: "Display", path: dir + "/Display", abbr: "DPM" },
    { header: "Pump", path: dir + "/Pump", abbr: "PMM" },
    { header: "Flow Control", path: dir + "/Flow Control", abbr: "FCM" },
    { header: "Temperature", path: dir + "/Temperature", abbr: "TEM" },
    {
      header: "Water Pressure",
      path: dir + "/Water Pressure",
      abbr: "WPM",
      footing:
        "All modules at first run are automatically Named, you can rename them from each module menu",
    },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default ModuleConfig;
