import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Schedules";
  let cards = [];
  props.constants["Irrigation Modules"].schedules.map((s) => {
    cards.push({
      key: s,
      header: s,
      path: dir + "/" + s,
    });
    return false;
  });
  cards.push({
    key: 0,
    header: "Custom",
    path: dir + "/Custom",
  });

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
