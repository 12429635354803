import React from "react";
import Card from "../../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <select className="custom-select mr-sm-2">
        <option>Not Reversed</option>
        <option>Reversed</option>
      </select>
      <small className="form-text text-muted rtl text-justify mt-4">
        بعد از انجام عملیات منطقی بین همه Event ها با استفاده از اپراتور انتخاب
        شده نتیجه نهایی میتواند با Reverse معکوس گردد و به عبارتی در ۱- ضرب گردد
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Reverse",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
