import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Starting Times";

  const cards = [
    {
      header: "Early Morning",
      path: dir + "/Early Morning",
    },
    {
      header: "Morning",
      path: dir + "/Morning",
    },
    {
      header: "Noon",
      path: dir + "/Noon",
    },
    {
      header: "Afternoon",
      path: dir + "/Afternoon",
    },
    {
      header: "Night",
      path: dir + "/Night",
    },
    {
      header: "Midnight",
      path: dir + "/Midnight",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
