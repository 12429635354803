import React from "react";
import { Link } from "react-router-dom";

function Card(props) {
  let titre = "";
  if (props.card.titre) {
    titre = (
      <div className="card-header text-muted bg-white">{props.card.titre}</div>
    );
  }
  let cardBody = "";
  if (props.card.body) {
    cardBody = <div className="card-body">{props.card.body}</div>;
  }
  let footing = "";
  if (props.card.footing) {
    footing = (
      <div className="card-footer border-top text-muted alert-white pt-1 pb-3 text-center small">
        {props.card.footing}
      </div>
    );
  }

  // situation icon
  let header = "";
  let abbr = "";
  let situation = "";
  if (props.card.hasOwnProperty("situation")) {
    if (props.card.situation) {
      situation = (
        <small className="btn btn-success ss-w-status btn-sm">Alive</small>
      );
    } else {
      situation = (
        <small className="btn btn-warning ss-w-status btn-sm">Idle</small>
      );
    }
    header = (
      <div className="row">
        <div className="col text-left pt-1">
          {props.card.situationHeader
            ? props.card.situationHeader
            : "Situation"}
        </div>
        <div className="col text-right">{situation}</div>
      </div>
    );
  } else {
    abbr = props.card.hasOwnProperty("abbr") ? (
      <small className="badge badge-primary mx-2">{props.card.abbr}</small>
    ) : (
      ""
    );
    header = (
      <React.Fragment>
        <div className="row">
          <div className="col">{props.card.header} </div>
          <div className="col-auto text-right">{abbr}</div>
        </div>
      </React.Fragment>
    );
  }

  // link or without
  let butnLink = "";
  if (props.card.hasOwnProperty("path")) {
    butnLink = (
      <Link to={props.card.path}>
        <button className="btn btn-link w-100 text-left">{header}</button>
      </Link>
    );
  } else if (props.card.hasOwnProperty("onClick")) {
    butnLink = (
      <button
        onClick={props.card.onClick}
        className="btn btn-link w-100 text-left"
      >
        {header}
      </button>
    );
  } else {
    butnLink = (
      <button className="btn btn-link w-100 text-left">{header}</button>
    );
  }

  let cardHeader = "";
  if (props.card.header) {
    cardHeader = (
      <div className="card-header">
        <h2 className="mb-0">{butnLink}</h2>
      </div>
    );
  }

  return (
    <div className="card">
      {titre}
      {cardHeader}
      {cardBody}
      {footing}
    </div>
  );
}

export default Card;
