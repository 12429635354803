import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "General";

  const cards = [
    {
      header: "Starting Times",
      path: dir + "/Starting Times",
    },
    {
      header: "Durations",
      path: dir + "/Durations",
    },
    {
      header: "Schedules",
      path: dir + "/Schedules",
    },
    {
      header: "Cycle & Soak",
      path: dir + "/Cycle & Soak",
    },
    {
      header: "Seasonal Coefficient",
      path: dir + "/Seasonal Coefficient",
    },
    {
      header: "Restore Default",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
