import React from "react";
import Card from "../../Card";
import { Link } from "react-router-dom";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-4 mt-1">
          <label>Name</label>
        </div>
        <div className="col-sm-8 mb-2">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder=""
          />
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify mt-4">
        انتخاب نام برای Event جدید. امکان ایجاد Event جدید در این دمو وجود ندارد
        و صرفا جهت نمایش قرار داده شده است
      </small>

      <Link to={`Event ${props.constants["Actuator Modules"].timeEvents + 1}`}>
        <button id="OK" className="btn btn-primary w-100 my-3">
          OK
        </button>
      </Link>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Define Event",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
