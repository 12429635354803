import React from "react";
import Card from "../Card";

function comp(props) {
  let hr = [];
  let min = [];
  for (let i = 0; i <= 59; i++) min.push(<option>{i}</option>);
  switch (props.name) {
    case "Early Morning":
      for (let i = 4; i <= 7; i++) hr.push(<option>{i}</option>);
      break;

    case "Morning":
      for (let i = 8; i <= 11; i++) hr.push(<option>{i}</option>);
      break;

    case "Noon":
      for (let i = 12; i <= 15; i++) hr.push(<option>{i}</option>);
      break;

    case "Afternoon":
      for (let i = 16; i <= 19; i++) hr.push(<option>{i}</option>);
      break;

    case "Night":
      for (let i = 20; i <= 23; i++) hr.push(<option>{i}</option>);
      break;

    case "Midnight":
      hr.push(<option>24</option>);
      for (let i = 1; i <= 3; i++) hr.push(<option>{i}</option>);
      break;

    default:
      break;
  }
  const cardBody = (
    <React.Fragment>
      <div className="form-row">
        <div className="col-md-3 mb-3">
          <select className="custom-select mr-sm-2">
            <option>Hour</option>
            {hr}
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <select className="custom-select mr-sm-2">
            <option>Minute</option>
            {min}
          </select>
        </div>
      </div>
    </React.Fragment>
  );

  const cards = [
    {
      header: `${props.name}`,
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
