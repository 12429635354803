import React from "react";
import Card from "./Card";

function Irrigation() {
  const dir = "Irrigation";
  const cards = [
    { header: "General", path: dir + "/General" },
    { header: "Valves", path: dir + "/Valves" },
    { header: "Pumps", path: dir + "/Pumps" },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default Irrigation;
