import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Module " + props.id;
  let i;
  let arr = [];
  arr.push({
    header: "Define",
    path: dir + "/Define",
  });

  for (i = 1; i <= props.constants["Actuator Modules"].ports; i++) {
    arr.push({
      header: "Port " + i,
      path: dir + "/Port " + i,
    });
  }

  const cards = arr;

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
