import React from "react";
import Card from "./Card";

function comp() {
  const dir = "Program";
  const cards = [
    {
      header: "Button Calibration",
      path: dir + "/Button Calibration",
    },
    {
      header: "Button Assignment",
      path: dir + "/Button Assignment",
    },
    {
      header: "Time Scene",
      path: dir + "/Time Scene",
    },
    {
      header: "Sensor Scene",
      path: dir + "/Sensor Scene",
    },
    {
      header: "Monitoring",
      path: dir + "/Monitoring",
    },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
