import React from "react";
import Card from "../../Card";

function comp(props) {
  let min = [],
    hr = [];
  for (let i = 0; i < 60; i++) {
    min.push(<option>{i}</option>);
  }
  for (let i = 0; i < 24; i++) {
    hr.push(<option>{i}</option>);
  }
  const cardBody = (
    <React.Fragment>
      <div className="form-row">
        <div className="col-md-3 mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Days</option>
            <option>1</option>
            <option>2</option>
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Hour</option>
            {hr}
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Minute</option>
            {min}
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Second</option>
            {min}
          </select>
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify">
        امکان تنظیم زمان برای سناریوی تعریف شده و یا اینکه خالی بماند و زمان
        تعریف نشده باشد
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Duration",
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing: "Max Duration 48 Hours",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
