import React from "react";
import Card from "../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="form-row">
        <div className="col-12 mb-1">
          <select
            defaultValue={Math.round(Math.random())}
            className="form-control form-control"
          >
            <option value="0">OFF</option>
            <option value="1">ON</option>
          </select>
        </div>
      </div>
      <div className="bd-callout bd-callout-info">
        <p> More options will be added soon!</p>{" "}
        <small className="text-muted">under development</small>
      </div>
    </React.Fragment>
  );

  const cards = [
    {
      header: `SMS`,
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
