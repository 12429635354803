import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Pumps";
  let i;
  let arr = [];
  for (i = 1; i <= props.constants["Pump Modules"].modules; i++) {
    arr.push({
      header: "Pump " + i + " <named>",
      situation: Math.round(Math.random()),
      situationHeader: `Pump ${i}`,
    });
  }

  const cards = arr;
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
