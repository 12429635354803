import React from "react";
import Card from "../Card";
import { Link } from "react-router-dom";

function Power() {
  const cardBody = (
    <React.Fragment>
      <button
        onClick={() => window.history.back(-1)}
        className="btn btn-danger w-100"
      >
        Set Counter to ZERO
      </button>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Are you sure?",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default Power;
