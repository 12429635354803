import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Module " + props.id;
  let i;
  let arr = [];
  arr.push({
    header: "Define",
    path: dir + "/Define",
  });

  const cards = arr;

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
