import React from "react";
import Card from "../../Card";

function comp(props) {
  const dir = "DIM " + props.id;

  const cards = [
    // {
    //   header: "Situation",
    //   situation: Math.round(Math.random()),
    // },
    { header: "Tap", path: dir + "/Tap" },
    { header: "Long Push 1", path: dir + "/Long Push 1" },
    { header: "Long Push 2", path: dir + "/Long Push 2" },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
