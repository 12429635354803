import React from "react";
import Card from "../Card";
import { Link } from "react-router-dom";
import queryString from "query-string";

function comp(props) {
  const parsed = queryString.parse(props.location.search);
  const buttonVar =
    parsed.swt === "1" ? (
      <Link to="?swt=0">
        <button className="btn btn-secondary w-100">Stop</button>
      </Link>
    ) : (
      <Link to="?swt=1">
        <button className="btn btn-primary w-100">Run</button>
      </Link>
    );
  let hr = [],
    min = [],
    sec = [];
  let k = 0;
  let footing = "";
  for (k = 1; k <= 24; k++) {
    hr.push(<option key={k}>{k}</option>);
  }
  for (k = 1; k <= 59; k++) {
    min.push(<option key={k}>{k}</option>);
  }
  for (k = 1; k <= 59; k++) {
    sec.push(<option key={k}>{k}</option>);
  }

  if (hr !== undefined) {
    if (hr.length !== 0) {
      hr = (
        <select className="custom-select">
          <option>Hour</option>
          {hr}
        </select>
      );
    } else {
      hr = (
        <select className="custom-select" disabled>
          <option>Hour</option>
        </select>
      );
    }
  }
  if (min !== undefined) {
    if (min.length !== 0) {
      min = (
        <select className="custom-select">
          <option>Minute</option>
          {min}
        </select>
      );
    } else {
      min = (
        <select className="custom-select" disabled>
          <option>Minute</option>
        </select>
      );
    }
  }
  if (sec !== undefined) {
    if (sec.length !== 0) {
      sec = (
        <select className="custom-select">
          <option>Second</option>
          {sec}
        </select>
      );
    } else {
      sec = (
        <select className="custom-select" disabled>
          <option>Second</option>
        </select>
      );
    }
  }

  const cardBody = (
    <React.Fragment>
      <div className="form-row justify-content-center">
        <div className="col-md-3 mb-3">{hr}</div>
        <div className="col-md-3 mb-3">{min}</div>
        <div className="col-md-3 mb-3">{sec}</div>
      </div>
      <div className="form-row justify-content-center">
        <div className="col-12 my-2">{buttonVar}</div>
      </div>

      <small className="form-text text-muted rtl text-justify">
        راه اندازی خط به صورت دستی. چنانچه زمانی مشخص نشود،‌ شیر به مدت 5 دقیقه
        روشن خواهد بود
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: `Manual`,
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing: footing,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
