import React from "react";
import Card from "./Card";

function comp() {
  const dir = "Machine";

  const cards = [
    { header: "Power", path: dir + "/Power" },
    { header: "Restart", path: dir + "/Restart" },
    { header: "Errors", path: dir + "/Errors" },
    { header: "Time & Date", path: dir + "/Time & Date" },
    { header: "Blinking Intervals", path: dir + "/Blinking Intervals" },
    { header: "Factory Reset", path: dir + "/Factory Reset" },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
