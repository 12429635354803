import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Durations";

  const cards = [
    {
      header: "Very Low",
      path: dir + "/Very Low",
    },
    {
      header: "Low",
      path: dir + "/Low",
    },
    {
      header: "Medium",
      path: dir + "/Medium",
    },
    {
      header: "High",
      path: dir + "/High",
    },
    {
      header: "Very High",
      path: dir + "/Very High",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
