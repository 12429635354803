import React from "react";
import Card from "../../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <select className="custom-select mr-sm-2">
        <option>AND</option>
        <option>OR</option>
      </select>
      <small className="form-text text-muted rtl text-justify mt-4">
        همه Event های انتخاب شده با این اپراتور تحت عملیات منطقی قرار میگیرند و
        نتیجه عملیات تعیین کننده ۱ یا ۰ بودن و یا به بیان بهتر روشن و یا خاموش
        بودن پورت Actuator انتخابی میشود
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Operator",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
