import React from "react";
import Card from "../../Card";

function comp(props) {
  const dir = "MNM " + props.portId;

  const cards = [
    {
      header: "Situation",
      situation: Math.round(Math.random()),
    },
    {
      header: "Module Port List",
      path: dir + "/Module Port List",
    },
    {
      header: "Operator",
      path: dir + "/Operator",
    },
    {
      header: "Reverse",
      path: dir + "/Reverse",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
