import React from "react";
import Card from "../../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-4 mt-1">
          <label>New Name</label>
        </div>
        <div className="col-sm-8 mb-2">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder=""
          />
        </div>
      </div>
      <button
        id="OK"
        onClick={() => window.history.go(-1)}
        className="btn btn-primary w-100 my-3"
      >
        OK
      </button>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Rename Event",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
