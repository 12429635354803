import React from "react";
import Card from "../Card";

function comp(props) {
  const arr = [
    <React.Fragment>
      <div class="col-12 mb-2">
        <select
          class="custom-select mr-sm-2"
          defaultValue={Math.floor(Math.random() * 3) + 1}
        >
          <option value="0">No</option>
          <option value="1">Cycle & Soak 1</option>
          <option value="2">Cycle & Soak 2</option>
        </select>
      </div>
    </React.Fragment>,
  ];

  const cardBody = (
    <React.Fragment>
      <div className="form-group row">{arr}</div>
      <small className="form-text text-muted rtl text-justify mt-4">
        این ویژگی در ۲ مود از طریق منوی General تنظیم می‌گردد. به عنوان مثال
        برای یک گیاه ما نیاز به آبیاری به مدت ۱۰ دقیقه در روز را داریم.
      </small>
      <small className="form-text text-muted rtl text-justify my-2">
        این گیاه در گلدانی قرار دارد که اگر بیشتر از ۱۵ ثانیه ممتد آبیاری شود،
        آب بر روی خاک انباشته شده و از کناره های گلدان به زمین میریزد.
      </small>
      <small className="form-text text-muted rtl text-justify my-2">
        بنابراین برای اینکه هم گیاه مجموعا ۱۰ دقیقه آبیاری شود و هم آب سرریز
        نشود، باید هر ۱۵ ثانیه (حد سرریزی) آبیاری متوقف شود و پس از مدتی صبر
        کردن برای فرونشستن آب در خاک ادامه آبیاری به مدت ۱۵ ثانیه مجددا انجام
        گردد.
      </small>
      <small className="form-text text-muted rtl text-justify my-2">
        این فرآیند توقف و ادامه آبیاری آنقدری ادامه پیدا میکند تا مجموع زمان
        آبیاری به زمان مقرر برسد که در این مثال ۱۰ دقیقه است.
      </small>
      <small className="form-text text-muted rtl text-justify my-2">
        به هر کدام از زمان های آبیاری یک سیکل یا Cycle و به هر کدام از زمان های
        توقف برای فرونشستن آب در خاک Soak یا خیس خوردن اطلاق می‌گردد.
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Cycle & Soak",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
