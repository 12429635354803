import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Module " + props.id;
  const cards = [
    {
      header: "Define",
      path: dir + "/Define",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
