import React from "react";
import Card from "../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-4 mt-1">
          <label>ON Duration</label>
        </div>
        <div className="col-sm-8 mb-2">
          <input
            type="number"
            className="form-control form-control-sm"
            placeholder="miliseconds"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4 mt-1">
          <label>OFF Duration</label>
        </div>
        <div className="col-sm-8 mb-2">
          <input
            type="number"
            className="form-control form-control-sm"
            placeholder="miliseconds"
          />
        </div>
      </div>

      <small className="form-text text-muted rtl text-justify mt-4">
        زمان روشن و خاموش شدن پورت های Output در هنگام چشمک زدن
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Blinking Intervals",
      path: "#",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
