import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Report";

  const cards = [
    {
      header: "Report Texts",
      path: dir + "/Report Texts",
    },
    {
      header: "Display Modules",
      path: dir + "/Display Modules",
    },
    {
      header: "Internal",
      path: dir + "/Internal",
    },
    {
      header: "SMS",
      path: dir + "/SMS",
    },
    {
      header: "Wifi",
      path: dir + "/Wifi",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
