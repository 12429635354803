import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Report Texts";
  let i;
  let arr = [];
  for (i = 1; i <= props.constants["Display Modules"].modules; i++) {
    arr.push({
      header: "Report Text " + i,
      path: dir + "/Report Text " + i,
    });
  }
  arr.push({
    header: "New Report Text",
  });
  const cards = arr;

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div
  //     key="notif"
  //     className="py-4 alert alert-warning rtl text-justify"
  //     role="alert"
  //   >
  //     به تعداد ماژول های Display تشخیص داده شده به صورت خودکار تعدادی
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
