import React from "react";
import Card from "../../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="form-row">
        <div className="col-md-3 mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Hour</option>
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Minute</option>
          </select>
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify">
        تنظیم زمان شروع Event
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Time",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
