import React from "react";
import Card from "../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="form-row">
        <div className="col-12 mb-1">
          <select
            defaultValue={Math.floor(Math.random() * 3)}
            className="form-control form-control"
          >
            <option value="0">None</option>
            <option value="1">Report 1</option>
            <option value="2">Report 2</option>
            <option value="3">Report 3</option>
          </select>
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify mt-4">
        با افزودن هر کدام از این متغییر ها در انتها آرایه ای از این متغییر ها به
        ماژول انتخابی ارسال میگردد و به حالت Loop و یا دائم نمایش داده میشود.
        همچنین کاربر میتواند از طریق دکمه ای که بر روی ماژول Display جهت کنترل
        آن قرار دارد مود نمایش اطلاعات را تعیین کند. به عنوان مثال با هر Tap
        داده بعدی بر روی OLED نمایش داده می‌گردد و با نگه داشتن همان دکمه
        نمایشگر به حالت Slideshow وارد میشود و اطلاعات را به ترتیب نمایش میدهد.
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: `Display Module ${props.id}`,
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
