// react
import React from "react";
import Nav from "./components/Nav";
import { BrowserRouter as Router } from "react-router-dom";
import RoutingAddr from "./Route";
// app components
// import Constants from "./constants";

function App(props) {
  // const constants = Constants;

  return (
    <div className="container my-3">
      <div className="accordion">
        <Router>
          <Nav />
          {/* 
          <button
            id="OK"
            onClick={() => window.history.go(-1)}
            className="btn btn-light mb-3 px-5"
          >
            Back
          </button> */}
          <RoutingAddr />
        </Router>
      </div>
    </div>
  );
}

export default App;
