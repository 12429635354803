import React from "react";
import Card from "../Card";

function comp(props) {
  // console.log(props);

  const cardBody = (
    <React.Fragment>
      <div className="form-row my-3 justify-content-center">
        <label class="col-4 mt-2">Maximum Pressure</label>
        <div class="col-8 mb-2">
          <input
            className="form-control"
            type="number"
            placeholder="bar"
            min="0"
            max="200"
          />
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify">
        تنظیم حد بالایی یا ماکس تشخیص سنسور جهت محاسبه فشار خط
      </small>
      <small className="form-text text-muted rtl text-justify">
        سنسور آنالوگ فشار سنج با خروجی جریانی مقدار فشار رو اندازه میگیره. به
        طور مثال خروجی سنسور بین 4 میلی امپر تا 20 میلی امپر تغییر میکند. اگر
        خروجی 20 میلی امپر باشد به این معنی هستش که فشار تشخیص داده شده، حد
        بالایی و یا ماکزیمم سنسور می‌باشد. به طور مثال در خطوط لوله های آب داخل
        ساختمان از سنسوری استفاده می‌شود که حد تشخیص آن در حالت ماکزیمم 15 بار
        می‌باشد و چنانچه جریان خروجی 20 میلی امپر باشد، یعنی فشار 15بار است. اگه
        جریان 4 میلی امپر باشد یعنی فشار حد پایین یا 0 است. با تعیین مقدار حد
        بالای سنسور از نرم افزار، امکان محاسبه فشار مهیا میگردد.
      </small>
      <small className="form-text text-muted rtl text-justify"></small>
    </React.Fragment>
  );

  // const dir = "Port " + props.id;

  // let i;
  // let arr = [];
  // for (i = 1; i <= props.constants["Input Modules"].ports; i++) {
  //   arr.push({
  //     header: "Port " + i,
  //     path: dir + "/Port " + i,
  //   });
  // }
  // const cards = arr;

  const cards = [
    {
      titre: "Select Range",
      path: "#",
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing: "Minimum Pressure is 0 and Maximum limit is set to 200bar",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
