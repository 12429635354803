import React from "react";
import Card from "../Card";

function comp(props) {
  let i;
  let arr = [];
  let mods = [];
  let valueArr = ["Actuator", "Button", "Sensor", "Valve"];

  // modules options for module selector
  for (
    i = 1;
    i <=
    props.constants["Actuator Modules"].modules *
      props.constants["Actuator Modules"].ports;
    i++
  ) {
    mods.push(
      <option key={"Actuator" + i} value={"Actuator" + i}>
        Actuator Port {i}
      </option>
    );
  }
  for (
    i = 1;
    i <=
    props.constants["Input Modules"].modules *
      props.constants["Input Modules"].buttons;
    i++
  ) {
    mods.push(
      <option key={"Button" + i} value={"Button" + i}>
        Button Port {i}
      </option>
    );
  }
  for (
    i = 1;
    i <=
    props.constants["Input Modules"].modules *
      props.constants["Input Modules"].sensors;
    i++
  ) {
    mods.push(
      <option key={"Sensor" + i} value={"Sensor" + i}>
        Sensor Port {i}
      </option>
    );
  }
  for (
    i = 1;
    i <=
    props.constants["Irrigation Modules"].modules *
      props.constants["Irrigation Modules"].ports;
    i++
  ) {
    mods.push(
      <option key={"Valve" + i} value={"Valve" + i}>
        Valve Port {i}
      </option>
    );
  }

  // module selectors
  for (i = 1; i <= 7; i++) {
    arr.push(
      <React.Fragment key={i}>
        <div className="col-6 mb-1">
          <label className="form-control-sm">Module Port {i}</label>
        </div>
        <div className="col-6 mb-1">
          <select
            className="form-control form-control-sm"
            defaultValue={
              valueArr[Math.floor(Math.random() * 4)] +
              Math.floor(Math.random() * 10) +
              1
            }
          >
            <option value="0">No</option>
            {mods}
          </select>
        </div>
      </React.Fragment>
    );
  }

  const cardBody = (
    <React.Fragment>
      <div className="form-row">
        <div className="col-6 mb-1">
          <label className="form-control-sm">Clock</label>
        </div>
        <div className="col-6 mb-1">
          <select
            defaultValue={Math.round(Math.random())}
            className="form-control form-control-sm"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        <div className="col-6 mb-1">
          <label className="form-control-sm">Date</label>
        </div>
        <div className="col-6 mb-1">
          <select
            defaultValue={Math.round(Math.random())}
            className="form-control form-control-sm"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        <div className="col-6 mb-1">
          <label className="form-control-sm">Name</label>
        </div>
        <div className="col-6 mb-1">
          <select
            defaultValue={Math.round(Math.random())}
            className="form-control form-control-sm"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </div>
      <div className="form-row">{arr}</div>
      <small className="form-text text-muted rtl text-justify mt-4">
        با افزودن هر کدام از این متغییر ها در انتها آرایه ای از این متغییر ها به
        ماژول انتخابی ارسال میگردد و به حالت Loop و یا دائم نمایش داده میشود.
        همچنین کاربر میتواند از طریق دکمه ای که بر روی ماژول Display جهت کنترل
        آن قرار دارد مود نمایش اطلاعات را تعیین کند. به عنوان مثال با هر Tap
        داده بعدی بر روی OLED نمایش داده می‌گردد و با نگه داشتن همان دکمه
        نمایشگر به حالت Slideshow وارد میشود و اطلاعات را به ترتیب نمایش میدهد.
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: `Report Text ${props.id}`,
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
