import React from "react";
import Card from "../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <select className="custom-select mr-sm-2">
        <option selected>Active</option>
        <option value="1">Disabled</option>
      </select>
      <small className="form-text text-muted rtl text-justify">
        تنظیم خودکار زمان از روی شبکه
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Time",
      path: "#",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
