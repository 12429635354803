import React from "react";
// import ReactDOM from "react-dom";
import { Route, Link } from "react-router-dom";
// styles

// replace underscores with spaces in path names
const formatLeafName = (leaf) => leaf.replace("_", " ");

// create a path based on the leaf position in the branch
const formatPath = (branch, index) => branch.slice(0, index + 1).join("/");

// output the individual breadcrumb links
const BreadCrumb = (props) => {
  const { leaf, index, branch } = props,
    leafPath = formatPath(branch, index),
    leafName = index === 0 ? "Home" : formatLeafName(leaf),
    leafItem =
      index + 1 < branch.length ? (
        <li className="breadcrumb-item">
          <Link to={leafPath}>{leafName}</Link>
        </li>
      ) : (
        <li className="breadcrumb-item">{leafName}</li>
      );
  // the slug doesn't need a link or a separator, so we output just the leaf name

  return leafItem;
};

const BreadCrumbList = (props) => {
  const path = props.match.url,
    listItems =
      // make sure we're not home (home return '/' on url)
      path.length >= 1 &&
      path
        // create an array of leaf names
        .split("/")
        // send our new array to BreadCrumb for formating
        .map((leaf, index, branch) => (
          <BreadCrumb leaf={leaf} index={index} branch={branch} key={index} />
        ));

  // listItem will exist anywhere but home
  return listItems && <ol className="breadcrumb">{listItems}</ol>;
};

const BackButtn = (props) => {
  let upPath = "";
  const path = props.match.url,
    listItems =
      // make sure we're not home (home return '/' on url)
      path.length >= 1 &&
      path
        // create an array of leaf names
        .split("/");
  listItems.map((leaf, index, branch) => {
    if ((index !== 0) & (index !== listItems.length - 1)) {
      upPath += `/${leaf}`;
    }
    return leaf;
  });
  // send our new array to BreadCrumb for formating
  // listItem will exist anywhere but home
  return (
    <Link to={upPath}>
      <button className="btn btn-light mb-3 px-5">Back</button>
    </Link>
  );
};

const Nav = (props) => (
  <Route
    path="/*"
    render={({ match }) => (
      <React.Fragment>
        <BreadCrumbList match={match} />
        <BackButtn match={match} />
      </React.Fragment>
    )}
  />
);

export default Nav;
