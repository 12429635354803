import React from "react";
import Card from "../Card";

function comp(props) {
  let i;
  let arr = [];
  let durs = [];

  for (i = 0; i < props.constants["Irrigation Modules"].durations.length; i++) {
    durs.push(
      <option value={i}>
        {props.constants["Irrigation Modules"].durations[i]}
      </option>
    );
  }
  for (i = 1; i <= props.constants["Sensor Modules"].modules; i++) {
    arr.push(
      <React.Fragment>
        <label class="col-4 col-md-2 mt-2">
          Sensor {i} <small className="text-muted ml-2">named</small>
        </label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
  // arr = (
  //   <React.Fragment>
  //     <div className="col text-center my-3">
  //       <small className="text-muted">empty!</small>
  //     </div>
  //   </React.Fragment>
  // );

  const cardBody = (
    <React.Fragment>
      <div className="form-group row">{arr}</div>
      <hr />
      <div className="form-group row">
        <label class="col-4 col-md-2 mt-2">Operator</label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">AND</option>
            <option value="1">OR</option>
          </select>
        </div>
        <label class="col-4 col-md-2 mt-2">Reverse</label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">Reversed</option>
            <option value="1">Not Reversed</option>
          </select>
        </div>
        <label class="col-4 col-md-2 mt-2">Duration</label>
        <div class="col-8 col-md-4 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0">None</option>
            {durs}
          </select>
        </div>
      </div>

      <small className="form-text text-muted rtl text-justify">
        تمامی سنسورهایی که توسط سیستم هوشمند شناسایی شده اند در اینجا لیست
        میگردند و میتوان هر Valve را به هر کدام از سنسورها حساس کرد و به این
        صورت با تغییر وضعیت سنسور وضعیت Valve هم تغییر خواهد کرد که بسته به
        تنظیمات سنسور که NC باشد. که در اینجا یا تغییر وضعیت سنسور،‌باعث روشن
        شدن شیر برقی تا زمانی که سنسور تغییر وضعیت ندهد و یا زمان Duration تعیین
        شده به پایان برد میشود.
      </small>

      <button
        id="OK"
        onClick={() => window.history.go(-1)}
        className="btn btn-primary w-100 my-3"
      >
        OK
      </button>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Sensorstart",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
