import React from "react";
import Card from "../Card";

function comp(props) {
  // console.log(props.constants["Monitoring Modules"]);

  const dir = "Monitoring";
  let i;
  let arr = [];
  for (i = 1; i <= props.constants["Monitoring Modules"].modules; i++) {
    arr.push({
      header: "Module " + i,
      path: dir + "/Module " + i,
    });
  }

  const cards = arr;
  // const cards = [
  //   {
  //     header: "Module " + props.constants["Monitoring Modules"].modules,
  //     path: dir + "/Module " + props.constants["Monitoring Modules"].modules,
  //   },
  // ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
