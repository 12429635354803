import React from "react";
import Card from "../Card";

function Power() {
  const cardBody = (
    <React.Fragment>
      <select defaultValue="ON" className="custom-select mr-sm-2 my-2">
        <option value="ON">ON</option>
        <option value="OFF">OFF</option>
      </select>
      <small className="form-text text-muted rtl text-justify">
        روشن و یا خاموش بودن کل دستگاه (به استثنای سیستم آبیاری)
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Power",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default Power;
