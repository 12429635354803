import React from "react";
import Card from "../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="form-row">
        <div className="col-1 mt-2 mx-3">
          <label htmlFor="">Cycle</label>
        </div>

        <div className="col mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Minute</option>
          </select>
        </div>
        <div className="col mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Second</option>
          </select>
        </div>
      </div>
      <div className="form-row">
        <div className="col-1 mt-2 mx-3">
          <label htmlFor="">Soak</label>
        </div>

        <div className="col mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Minute</option>
          </select>
        </div>
        <div className="col mb-3">
          <select className="custom-select mr-sm-2">
            <option selected>Second</option>
          </select>
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify mt-4">
        این ویژگی در ۲ مود از طریق منوی General تنظیم می‌گردد. به عنوان مثال
        برای یک گیاه ما نیاز به آبیاری به مدت ۱۰ دقیقه در روز را داریم.
      </small>
      <small className="form-text text-muted rtl text-justify my-2">
        این گیاه در گلدانی قرار دارد که اگر بیشتر از ۱۵ ثانیه ممتد آبیاری شود،
        آب بر روی خاک انباشته شده و از کناره های گلدان به زمین میریزد.
      </small>
      <small className="form-text text-muted rtl text-justify my-2">
        بنابراین برای اینکه هم گیاه مجموعا ۱۰ دقیقه آبیاری شود و هم آب سرریز
        نشود، باید هر ۱۵ ثانیه (حد سرریزی) آبیاری متوقف شود و پس از مدتی صبر
        کردن برای فرونشستن آب در خاک ادامه آبیاری به مدت ۱۵ ثانیه مجددا انجام
        گردد.
      </small>
      <small className="form-text text-muted rtl text-justify my-2">
        این فرآیند توقف و ادامه آبیاری آنقدری ادامه پیدا میکند تا مجموع زمان
        آبیاری به زمان مقرر برسد که در این مثال ۱۰ دقیقه است.
      </small>
      <small className="form-text text-muted rtl text-justify my-2">
        به هر کدام از زمان های آبیاری یک سیکل یا Cycle و به هر کدام از زمان های
        توقف برای فرونشستن آب در خاک Soak یا خیس خوردن اطلاق می‌گردد.
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      titre: `Cycle & Soak ${props.id}`,
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing: "Min Cycle: 5s, Max Cycle: 300s or 5m",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
