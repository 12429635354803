import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Time & Date";

  const cards = [
    {
      header: "Time",
      path: dir + "/Time",
    },
    {
      header: "Date",
      path: dir + "/Date",
    },
    {
      header: "Auto via LAN",
      path: dir + "/Auto via LAN",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
