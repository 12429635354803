import React from "react";
import Card from "../Card";

function HCDigitalInputModN(props) {
  // console.log(props);

  const dir = "Module " + props.id;
  let i;
  let arr = [];
  arr.push({
    header: "Define",
    path: dir + "/Define",
    desc: "تغییر نام ماژول input",
  });

  for (i = 1; i <= props.constants["Input Modules"].ports; i++) {
    arr.push({
      header: "Port " + i,
      path: dir + "/Port " + i,
    });
  }

  const cards = arr;
  // const cards = [
  //   {
  //     header: "Module " + props.constants["Input Modules"].modules,
  //     path: dir + "/Module " + props.constants["Input Modules"].modules,
  //   },
  // ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default HCDigitalInputModN;
