import React from "react";
import Card from "./Card";

function Remote() {
  const dir = "Remote";
  const cards = [
    { header: "SMS", path: dir + "/SMS" },
    { header: "Wifi", path: dir + "/Wifi" },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default Remote;
