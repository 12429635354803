import React from "react";
import Card from "../Card";

function comp(props) {
  let hr = [],
    min = [],
    sec = [];
  let k = 0;
  let footing = "";
  switch (props.name) {
    case "Very Low":
      footing =
        "Very Low has a limit of setting to be under 60 seconds or 1 minute";
      for (k = 1; k <= 59; k++) {
        sec.push(<option>{k}</option>);
      }
      break;

    case "Low":
      footing =
        "Low has a limit of setting to be over 1 minute and under 3 minutes";

      for (k = 1; k <= 3; k++) {
        min.push(<option>{k}</option>);
      }
      break;

    case "Medium":
      footing =
        "Low has a limit of setting to be over 3 minutes and under 10 minutes";

      for (k = 4; k <= 10; k++) {
        min.push(<option>{k}</option>);
      }
      break;

    case "High":
      footing =
        "Low has a limit of setting to be over 10 minutes and under 60 minutes";

      for (k = 11; k <= 59; k++) {
        min.push(<option>{k}</option>);
      }
      break;

    case "Very High":
      footing =
        "Low has a limit of setting to be over 1 hour and under 24 hours";

      for (k = 1; k <= 24; k++) {
        hr.push(<option>{k}</option>);
      }
      break;

    default:
      break;
  }

  if (hr !== undefined) {
    if (hr.length !== 0) {
      hr = (
        <select className="custom-select">
          <option>Hour</option>
          {hr}
        </select>
      );
    } else {
      hr = (
        <select className="custom-select" disabled>
          <option>Hour</option>
        </select>
      );
    }
  }
  if (min !== undefined) {
    if (min.length !== 0) {
      min = (
        <select className="custom-select">
          <option>Minute</option>
          {min}
        </select>
      );
    } else {
      min = (
        <select className="custom-select" disabled>
          <option>Minute</option>
        </select>
      );
    }
  }
  if (sec !== undefined) {
    if (sec.length !== 0) {
      sec = (
        <select className="custom-select">
          <option>Second</option>
          {sec}
        </select>
      );
    } else {
      sec = (
        <select className="custom-select" disabled>
          <option>Second</option>
        </select>
      );
    }
  }

  const cardBody = (
    <React.Fragment>
      <div className="form-row justify-content-center">
        <div className="col-md-3 mb-3">{hr}</div>
        <div className="col-md-3 mb-3">{min}</div>
        <div className="col-md-3 mb-3">{sec}</div>
      </div>
    </React.Fragment>
  );

  const cards = [
    {
      titre: `${props.name}`,
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing: footing,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
