import React from "react";
import Card from "../Card";

function comp(props) {
  let arr = [];
  props.constants["Irrigation Modules"].schedules.map((s, index) => {
    arr.push(<option value={index}>{s}</option>);
    return false;
  });

  const cardBody = (
    <React.Fragment>
      <div className="form-group row">
        <div class="col-12 mb-2">
          <select
            class="custom-select mr-sm-2"
            defaultValue={Math.floor(Math.random() * 6) + 1}
          >
            {arr}
          </select>
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify mt-4">
        تخصیص یکی از برنامه های زمانبدی روزهای هفته تعریف شده به خط {props.id}
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Schedule",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
