import React from "react";
import Card from "../../Card";

function comp(props) {
  const cardBody = (
    <React.Fragment>
      <div className="form-row my-3">
        <div className="col-12 mb-1">
          <input
            type="text"
            className="form-control"
            placeholder="Enter Name"
            maxLength="20"
          />
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify mt-4">
        نامگذاری هر پورت در منو
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Define",
      path: "#",
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing: "Maximum Character Length 20",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
