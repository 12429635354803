import React from "react";
import Card from "../Card";

function comp(props) {
  const dir = "Valves";
  let i;
  let arr = [];
  let digit = "";
  for (
    i = 1;
    i <=
    props.constants["Irrigation Modules"].modules *
      props.constants["Irrigation Modules"].ports;
    i++
  ) {
    if (i < 10) {
      digit = "00" + i;
    }
    if ((i > 10) & (i < 100)) {
      digit = "0" + i;
    }
    arr.push({
      header: "IRM " + digit,
      path: dir + "/IRM " + digit,
    });
  }

  const cards = arr;
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
