export default {
  "Input Modules": { modules: 4, ports: 12, abbr: "DIM" },
  "Sensor Modules": { modules: 3, abbr: "SEM" },
  "Monitoring Modules": { modules: 2, ports: 6, abbr: "MNM" },
  "Actuator Modules": { modules: 5, ports: 6, abbr: "ACM", timeEvents: 3 },
  "Actuator Solo Modules": { modules: 4, abbr: "AOM", timeEvents: 3 },
  "Display Modules": { modules: 3, abbr: "DPM" },
  "Pump Modules": { modules: 3, abbr: "PMM" },
  "Flow Control Modules": { modules: 6, abbr: "FCM" },
  "Irrigation Modules": {
    modules: 2,
    ports: 8,
    abbr: "MNM",
    schedules: [
      "Once a Month",
      "Every 15 Days",
      "Once a Week",
      "Twice a Week",
      "Odd Days",
      "Even Days",
      "Every Day",
      "Custom",
    ],
    durations: ["Very Low", "Low", "Medium", "High", "Very High"],
  },
};
