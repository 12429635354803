import React from "react";
import Card from "../Card";

function comp() {
  const cardBody = (
    <React.Fragment>
      <div class="alert alert-danger" role="alert">
        <small className="badge badge-light mr-2">12:35</small>
        <small className="badge badge-light mr-2">Irrigation Module 2</small>
        <p className="mt-1 mb-0">24V/AC Power failure!</p>
      </div>
      <div class="alert alert-warning" role="alert">
        <small className="badge badge-light mr-2">12:35</small>
        <small className="badge badge-light mr-2">Actuator Module 4</small>
        <p className="mt-1 mb-0">Not responding!</p>
      </div>

      <small className="form-text text-muted rtl text-justify">
        تمامی Error های سیستم در این منو نمایش داده میشوند که دو نوع خطای
        "خطرناک" و "اخطار" تقسیم میشوند
      </small>
    </React.Fragment>
  );

  const cards = [
    {
      header: "Errors",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];

  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
