import React from "react";
import Card from "../Card";

function comp(props) {
  // console.log(props);

  const cardBody = (
    <React.Fragment>
      <div className="form-row my-3 justify-content-center">
        <label class="col-4 mb-2">Type</label>
        <div class="col-8 mb-2">
          <select
            class="custom-select mr-sm-2"
            value={Math.round(Math.random())}
          >
            <option value="0"></option>
            <option value="1">Yes</option>
          </select>
        </div>
      </div>
      <small className="form-text text-muted rtl text-justify">
        تعریف و تغییر نام ماژول پمپ که دارای یک پورت می‌باشد و پورت شامل سه رله
        می‌باشد. بسته به نوع استفاده می‌تواند به دو حالت دستور به اینورتر و یا
        راه اندازی مستقیم پمپ اقدام کند.
      </small>
    </React.Fragment>
  );

  // const dir = "Port " + props.id;

  // let i;
  // let arr = [];
  // for (i = 1; i <= props.constants["Input Modules"].ports; i++) {
  //   arr.push({
  //     header: "Port " + i,
  //     path: dir + "/Port " + i,
  //   });
  // }
  // const cards = arr;

  const cards = [
    {
      header: "Define",
      path: "#",
      body: <React.Fragment>{cardBody}</React.Fragment>,
      footing: "Maximum Character Length 20",
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
