import React from "react";
import Card from "../../Card";

function comp(props) {
  const dir = "Sensor Scene";
  let i;
  let arr = [];
  let digit = "";
  for (
    i = 1;
    i <=
    props.constants["Actuator Modules"].modules *
      props.constants["Actuator Modules"].ports;
    i++
  ) {
    if (i < 10) {
      digit = "00" + i;
    }
    if ((i > 10) & (i < 100)) {
      digit = "0" + i;
    }
    arr.push({
      header: "ACM " + digit,
      path: dir + "/ACM " + digit,
    });
  }
  for (i = 1; i <= props.constants["Actuator Solo Modules"].modules; i++) {
    if (i < 10) {
      digit = "00" + i;
    }
    if ((i > 10) & (i < 100)) {
      digit = "0" + i;
    }
    arr.push({
      header: "AOM " + digit,
      path: dir + "/AOM " + digit,
    });
  }

  const cards = arr;
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
