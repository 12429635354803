import React from "react";
import Card from "../../Card";

function comp(props) {
  const dir = props.name;

  const cards = [
    // {
    //   header: "Situation",
    //   situation: Math.round(Math.random()),
    // },
    {
      header: "Actuator List",
      path: dir + "/Actuator List",
    },
    {
      header: "Duration",
      path: dir + "/Duration",
    },
    {
      header: "Sensor Sensitivity",
      path: dir + "/Sensor Sensitivity",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
