import React from "react";
import Card from "../../Card";

function comp(props) {
  let i;
  let arr = [];
  let digit = "";
  for (
    i = 1;
    i <=
    props.constants["Actuator Modules"].modules *
      props.constants["Actuator Modules"].ports;
    i++
  ) {
    if (i < 10) {
      digit = "00" + i;
    }
    if ((i > 10) & (i < 100)) {
      digit = "0" + i;
    }
    arr.push(
      <React.Fragment>
        <label class="col-4 col-md-2 mb-2">ACM {digit}</label>
        <div class="col-8 col-md-4 mb-2">
          <select class="custom-select mr-sm-2">
            <option>None</option>
            <option>ON</option>
            <option>OFF</option>
            <option>Toggle</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
  for (i = 1; i <= props.constants["Actuator Solo Modules"].modules; i++) {
    if (i < 10) {
      digit = "00" + i;
    }
    if ((i > 10) & (i < 100)) {
      digit = "0" + i;
    }
    arr.push(
      <React.Fragment>
        <label class="col-4 col-md-2 mb-2">AOM {digit}</label>
        <div class="col-8 col-md-4 mb-2">
          <select class="custom-select mr-sm-2">
            <option>None</option>
            <option>ON</option>
            <option>OFF</option>
            <option>Toggle</option>
          </select>
        </div>
      </React.Fragment>
    );
  }

  const cardBody = (
    <React.Fragment>
      <div className="form-group row">{arr}</div>
      <small className="form-text text-muted rtl text-justify mt-4">
        برای مفهوم بودن بیشتر خیلی از منوهایی که بیش از حد تو در تو میشوند به
        صورت فرم انتخابی (مثل بالا) طراحی شده اند. بعد از انتخاب Button مورد نظر
        از منوی قبلی در این منو به کاربر لیستی از تمامی پورت های Actuator ارائه
        می‌گردد که به جای شماره پورت نامی که برایشان انتخاب شده از منوی تعاریف
        نمایش داده می‌شود.
      </small>
      <small className="form-text text-muted rtl text-justify mt-2">
        می‌توان همه پورت ها را به یک دکمه برای خاموش کردنشون نسبت داد و یا
        سناریوهای مختلفی را برای هر دکمه در نظر گرفت
      </small>

      <button
        id="OK"
        onClick={() => window.history.go(-1)}
        className="btn btn-primary w-100 my-3"
      >
        OK
      </button>
    </React.Fragment>
  );

  const cards = [
    {
      titre: "Actuator List",
      body: <React.Fragment>{cardBody}</React.Fragment>,
    },
  ];
  return cards.map((card, index) => <Card key={index} card={card} />);
}

export default comp;
