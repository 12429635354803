import React, { Component } from "react";
import Card from "../Card";

class Comp extends Component {
  state = {
    WeekDays: [
      "Saturday",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
    ],
    TWDays: [],
    TWDisabled: true,
  };

  setDays(arr, v = null) {
    let result = [];
    let i;
    for (i = 0; i < arr.length; i++) {
      if (arr[i] !== v) {
        result.push(
          <option key={i} value={arr[i]}>
            {arr[i]}
          </option>
        );
      }
    }

    return result;
  }
  componentDidMount() {
    this.setState({ TWDays: this.setDays(this.state.WeekDays) });
  }

  handleChange(v) {
    let newDays = this.setDays(this.state.WeekDays, v);
    this.setState({
      TWDays: newDays,
      TWDisabled: v === "None" ? true : false,
    });
  }
  render() {
    let cardBody;
    let result = [];
    let days = [];

    switch (this.props.name) {
      case "Once a Month":
        for (let i = 1; i <= 31; i++) {
          days.push(
            <option key={i} value={i}>
              {i}
            </option>
          );
        }
        result = (
          <React.Fragment>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Month Day</label>
              </div>
              <div className="col-6 mb-1">
                <select className="form-control form-control-sm">{days}</select>
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "Every 15 Days":
        for (let i = 1; i <= 16; i++) {
          days.push(
            <option key={i} value={i}>
              {i}, {i + 15}
            </option>
          );
        }
        result = (
          <React.Fragment>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Month Days</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.floor(Math.random() * 15 + 1)}
                  className="form-control form-control-sm"
                >
                  {days}
                </select>
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "Once a Week":
        result = (
          <React.Fragment>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Week Day</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.floor(Math.random() * 7 + 1)}
                  className="form-control form-control-sm"
                >
                  <option>Saturday</option>
                  <option>Sunday</option>
                  <option>Monday</option>
                  <option>Tuesday</option>
                  <option>Wenesday</option>
                  <option>Thursday</option>
                  <option>Friday</option>
                </select>
              </div>
            </div>
          </React.Fragment>
        );
        break;
      case "Twice a Week":
        // const days = this.setDays(this.WeekDays);
        result = (
          <React.Fragment>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Day 1</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  onChange={(e) => this.handleChange(e.target.value)}
                  className="form-control form-control-sm"
                >
                  <option>None</option>
                  <option>Saturday</option>
                  <option>Sunday</option>
                  <option>Monday</option>
                  <option>Tuesday</option>
                  <option>Wenesday</option>
                  <option>Thursday</option>
                  <option>Friday</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Day 2</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  disabled={this.state.TWDisabled}
                  className="form-control form-control-sm"
                >
                  {this.state.TWDays}
                </select>
              </div>
            </div>
            <small className="form-text text-muted rtl text-justify my-4">
              دو روز به دلخواه را برای آبیاری در نوع «دو روز در هفته» انتخاب
              کنید. به صورت خودکار باید دو روزبا هم متفاوت باشند که توسط برنامه
              این عمل صورت می‌پذیرد
            </small>
          </React.Fragment>
        );
        break;
      case "Odd Days":
        result = (
          <React.Fragment>
            <small className="form-text text-muted rtl text-center mt-4">
              به صورت خودکار روزهای فرد ماه در این برنامه قرار دارند
            </small>
            <small className="form-text text-muted rtl text-center">
              1, 3, 5 , 7 , 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31
            </small>
            {/* <Card card={{ header: "Define" }} /> */}
          </React.Fragment>
        );
        break;
      case "Even Days":
        result = (
          <React.Fragment>
            <small className="form-text text-muted rtl text-center mt-4">
              به صورت خودکار روزهای زوج ماه در این برنامه قرار دارند
            </small>
            <small className="form-text text-muted rtl text-center">
              2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30
            </small>
            {/* <Card card={{ header: "Define" }} /> */}
          </React.Fragment>
        );
        break;
      case "Every Day":
        result = (
          <React.Fragment>
            <small className="form-text text-muted rtl text-center mt-4">
              این برنامه برای هر روز تعریف شده است
            </small>
            <small className="badge badge-pill m-2 badge-light">Saturday</small>
            <small className="badge badge-pill m-2 badge-light">Sunday</small>
            <small className="badge badge-pill m-2 badge-light">Monday</small>
            <small className="badge badge-pill m-2 badge-light">Tuesday</small>
            <small className="badge badge-pill m-2 badge-light">
              Wednesday
            </small>
            <small className="badge badge-pill m-2 badge-light">Thrusday</small>
            <small className="badge badge-pill m-2 badge-light">Friday</small>
            {/* <Card card={{ header: "Define" }} /> */}
          </React.Fragment>
        );
        break;

      default:
        result = (
          <React.Fragment>
            <Card card={{ header: "Rename" }} />
            <div className="form-row mt-4">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Saturday</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.round(Math.random())}
                  className="form-control form-control-sm"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Sunday</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.round(Math.random())}
                  className="form-control form-control-sm"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Monday</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.round(Math.random())}
                  className="form-control form-control-sm"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Tuesday</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.round(Math.random())}
                  className="form-control form-control-sm"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Wednesday</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.round(Math.random())}
                  className="form-control form-control-sm"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Thursday</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.round(Math.random())}
                  className="form-control form-control-sm"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 mb-1">
                <label className="form-control-sm">Friday</label>
              </div>
              <div className="col-6 mb-1">
                <select
                  defaultValue={Math.round(Math.random())}
                  className="form-control form-control-sm"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <small className="form-text text-muted rtl text-justify my-4">
              در هر برنامه زمانبدنی که کلا ۴ عدد موجود میباشد میتوان ۴ برنامه
              مختلف با نام معین داشت که توانایی تعیین روزهای هفته ای که برنامه
              فعال هستند را دارند. بدین صورت میتوان به هر شیر آبیاری یکی از ۴
              عدد برنامه زمانبدی برای روز ها داده شود و به این صورت با ترکیب
              قسمت های مختلف برای همه نوع گیاهی بتوان آبیاری نمود.
            </small>
          </React.Fragment>
        );
        break;
    }
    cardBody = result;

    const cards = [
      {
        header: this.props.name,
        body: <React.Fragment>{cardBody}</React.Fragment>,
      },
    ];
    return cards.map((card, index) => <Card key={index} card={card} />);
  }
}

export default Comp;
