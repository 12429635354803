import React from "react";
import Card from "../../Card";

function comp(props) {
  const dir = "Monitoring";

  const cards = [
    {
      header: "Input Module",
      path: dir + "/Input Module",
    },
    {
      header: "Monitoring Module",
      path: dir + "/Monitoring Module",
    },
  ];

  let output = cards.map((card, index) => <Card key={index} card={card} />);

  // const notif = (
  //   <div key="notif" className="my-4 alert alert-warning" role="alert">
  //     This is a warning alert—check it out!
  //   </div>
  // );
  // output.push(notif);

  return output;
}

export default comp;
